<template>
  <div class="ces">
    <div class="title">服务中心</div>
    <div class="box">
      <a class="item alltime3" href="afterSale">
        <div class="ms">
          <s></s>
          <img src="../assets/img/service-b-4.png" alt="" />
          <img src="../assets/img/service-4.png" alt="" />
        </div>
        <div class="nt f_18">售后服务</div>
        <div class="ns f_12">您所享有的售后权益及保障</div>
      </a>

      <a class="item alltime3" href="use">
        <div class="ms">
          <s></s>
          <img src="../assets/img/service-b-3.png" alt="" />
          <img src="../assets/img/service-3.png" alt="" />
        </div>
        <div class="nt f_18">使用教程</div>
        <div class="ns f_12">教你如何正确使用美容仪护肤</div>
      </a>

      <a class="item alltime3" href="service#point10">
        <div class="ms">
          <s></s>
          <img src="../assets/img/service-b-2.png" alt="" />
          <img src="../assets/img/service-2.png" alt="" />
        </div>
        <div class="nt f_18">常见问题</div>
        <div class="ns f_12">产品使用常见问题及解答</div>
      </a>

      <a class="item alltime3" href="contact">
        <div class="ms">
          <s></s>
          <img src="../assets/img/service-b-1.png" alt="" />
          <img src="../assets/img/service-1.png" alt="" />
        </div>
        <div class="nt f_18">联系我们</div>
        <div class="ns f_12">有任何问题请联系我们</div>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: "pcService",
  props: {
    msg: String,
  },
};
</script>

<style scoped lang="scss">
.title {
  background: #000;
  color: #fff;
  text-align: center;
  font-size: 46px;
  font-family: 'HarmonyOS_Sans_SC';
  padding-top: 78px;
}

.ces {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  border-top: 2px solid;
  border-image: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #fed8be 50%, rgba(255, 255, 255, 0) 99%) 2 2 2 2;
}

.box {
  display: flex;
  padding: 80px 0 120px 0;
  width: 100%;
  background: #000;
  color: #fff;
  text-align: center;
  display: flex;
  justify-content: space-between;
  border-bottom: 2px solid;
  border-image: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #fed8be 50%, rgba(255, 255, 255, 0) 99%) 2 2 2 2;

}

.item {
  width: 25%;
  display: flex;
  flex-direction: column;
  align-items: center;
  // border-right: 1px #fff solid;
  /*首先我们设置边框只显示右侧，宽度为2px的实线。*/
  border-right: 2px solid;
  /*设置线性渐变*/
  border-image: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #fed8be 50%, rgba(255, 255, 255, 0) 99%) 2 2 2 2;

  &:last-child {
    border-right: none;
    // padding-right:0;
  }

  .nt {
    margin-bottom: 10px;
  }

  color: #fff;
}

.ms {
  width: 100px;
  height: 100px;
  background: url("../assets/img/service-bg-1.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;

  img {
    position: absolute;
    top: 25px;
    left: 27.5px;
    width: 45px;
    height: 51px;
  }

  margin-bottom: 20px;

}

.ms s {
  display: block;
  width: 100px;
  height: 100%;
  bottom: 0;
  left: 0;
  background: url("../assets/img/service-bg-2.png");
  position: absolute;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  opacity: 0;
}

.item .ms:hover s {
  opacity: 1;
}

.item .ms img:first-child {
  opacity: 0;
}

.item .ms:hover img:first-child {
  opacity: 1;
}

.item .ms img:last-child {
  opacity: 1;
}

.item .ms:hover img:last-child {
  opacity: 0;
}
</style>