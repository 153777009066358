
import axios from 'axios'
//构建统一 axios 实例
const http = axios.create({
    timeout: 1000000,
    // baseURL: 'https://paige.hotsupper.top',
    baseURL: 'https://www.ms-paige.com.cn',
    // baseURL: '/api',
    headers: {
        'Content-Type': 'application/json; charset=utf-8',
        'Access-Control-Allow-Origin':'*'
    }
})

// 请求超时
// axios.defaults.timeout = 100000;
// post请求头
// axios.defaults.headers.post['Content-Type'] = 'application/json;charset=UTF-8';
// 环境切换
// axios.defaults.baseURL = "http://paige.hotsupper.top"

// 响应拦截器
http.interceptors.response.use(response => {
    if (response.headers['content-type'] == 'application/msword') {
        return Promise.resolve(response)
    }

    if (response.data.code != '200') {

        if (response.config.resCode) {
            return Promise.resolve(response)
        } else {
            return Promise.reject(response.data.msg)
        }

    }
    return response;
}, err => {
    if (err.response && err.response.status === 509) {
        let html = err.response.data;
        let verifyWindow = window.open("", "_blank", "height=400,width=560");
        verifyWindow.document.write(html);
        verifyWindow.document.getElementById("baseUrl").value = 'http://192.168.110.150';
    } else {
        return Promise.reject('网络错误')
    }

})
export default {
    fetchGet(url, data = {}) {
        return new Promise((resolve, reject) => {
            http.get(url, { params: { ...data } }).then(res => {
                resolve(res.data)
            }).catch(error => {
                reject(error)
            })
        })
    },
    fetchPost(url, params = {}, config = {}) {
        return new Promise((resolve, reject) => {
            http.post(url, params, config).then(res => {
                resolve(res.data)
            }).catch(error => {
                reject(error)
            })
        })
    }
}