
import http from './request'
export const homePageVideos= (params) => {
  return http.fetchGet('/paige/homePage/videos', params)
}
export const homePageInformation= (params) => {
  return http.fetchGet('/paige/homePage/information', params)
}
export const brandHistory= (params) => {
  return http.fetchGet('/paige/about/brandHistory', params)
}
export const aboutPatent= (params) => {
  return http.fetchGet('/paige/about/patent', params)
}
export const stepIntoVideo= (params) => {
  return http.fetchGet('/paige/stepInto/video', params)
}
export const stepIntoInformation= (params) => {
  return http.fetchGet('/paige/stepInto/information', params)
}
export const usingTutorials= (params) => {
  return http.fetchGet('/paige/usingTutorials/video', params)
}

// param:{
// 	"phoneNum":"", //电话号码
// }
export const sendCode= (params) => {
  return http.fetchPost('/paige/serviceCenter/sendCode', params)
}
// param:{
// 	"userName":"", //用户名
// 	"phoneNum":"", //电话号码
// 	"question":"", //问题
// 	"code":"" //验证码
// }
export const contactUs= (params) => {
  return http.fetchPost('/paige/serviceCenter/contactUs', params)
}
export const maintenanceRegistration= (params) => {
  return http.fetchPost('/paige/serviceCenter/maintenanceRegistration', params)
}

export const productDetail= (params) => {
  return http.fetchGet('/paige/product/productDetail', params)
}

export const products= (params) => {
  return http.fetchGet('/paige/product/allProduct', params)
}

export const smallVideos= (params) => {
  return http.fetchGet('/paige/homePage/smallVideos', params)
}

export const carouselPicture= (params) => {
  return http.fetchGet('/paige/homePage/carouselPicture', params)
}

export const commonQuestionList= (params) => {
  return http.fetchGet('/paige/serviceCenter/commonQuestionList', params)
}

export const storeOptionList= (params) => {
  return http.fetchGet('/paige/store/storeOptionList', params)
}

export const queryStoreInfo= (params) => {
  return http.fetchGet('/paige/store/queryStoreInfo', params)
}

export const allProductTypes= (params) => {
  return http.fetchGet('/paige/backend/allProductTypes', params)
}