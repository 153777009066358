import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import(/* webpackChunkName: "about" */ '../views/contact.vue')
  },
  {
    path: '/check',
    name: 'check',
    component: () => import(/* webpackChunkName: "about" */ '../views/checkView.vue')
  },
  {
    path: '/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/paigeNews',
    name: 'paigeNews',
    component: () => import(/* webpackChunkName: "about" */ '../views/peigeNews.vue')
  },
  {
    path: '/use',
    name: 'use',
    component: () => import(/* webpackChunkName: "about" */ '../views/use.vue')
  },
  {
    path: '/service',
    name: 'serviceC',
    component: () => import(/* webpackChunkName: "about" */ '../views/serviceCenter.vue')
  },
  {
    path: '/buy',
    name: 'buy',
    component: () => import(/* webpackChunkName: "about" */ '../views/shopping.vue')
  },
  {
    path: '/news',
    name: 'pNews',
    component: () => import(/* webpackChunkName: "about" */ '../views/detailPaige.vue')
  },
  {
    path: '/policy',
    name: 'policy',
    component: () => import(/* webpackChunkName: "about" */ '../views/policy.vue')
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: () => import(/* webpackChunkName: "about" */ '../views/privacy.vue')
  },
  {
    path: '/introduce',
    name: 'introduce',
    component: () => import(/* webpackChunkName: "about" */ '../views/introduce.vue')
  },
  {
    path: '/afterSale',
    name: 'afterSale',
    component: () => import(/* webpackChunkName: "about" */ '../views/afterSaleService.vue')
  },
  {
    path: '/shopMap',
    name: 'shopMap',
    component: () => import(/* webpackChunkName: "about" */ '../views/shopMap.vue')
  },
  {
    path: '/productDetail',
    name: 'productDetail',
    component: () => import(/* webpackChunkName: "about" */ '../views/productDetail.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
