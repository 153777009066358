<template>
  <div class="hdbox">
    <!-- PC -->
    <div class="head">
      <div
        class="hedad_pc"
        :class="{ actii: isBg, bg: !isBg, notBg: isblackBg }"
      >
        <div class="htop">
          <div class="box clearfix">
            <div class="hlt alltime">
              <!-- 语言切换 -->
              <div class="a">
                <img src="../assets/img/icon-4.png" />
              </div>
              <div class="yy gle">
                <span class="en">简体中文</span>
                <div class="yox"></div>
                <div class="a2">
                  <div class="aox">
                    <a href="/">
                      <div><s></s> <span></span> 简体中文</div>
                    </a>
                    <!-- <a href="/en/index.html">
                      <div><s></s><span></span> English</div>
                    </a> -->
                    <a href="/">
                      <div><s></s><span></span> 繁体中文</div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="hrt alltime">
              <!-- 搜索 -->
              <!-- <form id="headersearchforms" action="search.html" method="get">
                <div class="bd">
                  <input
                    autocomplete="off"
                    type="text"
                    name="keywords"
                    placeholder="在Paige上搜索"
                    class="gle f_12"
                  />
                </div>
                <a
                  href=""
                  class="ss"
                  onclick="$('#headersearchforms').submit();"
                ></a>
                <div class="wz">
                  <img src="../assets/img/icon-2.png" />
                </div>
                <s></s>
              </form> -->
              <!-- 登录 -->
              <!-- <a href="#" class="a a4">
                <img src="../assets/img/icon-1.png" />
              </a> -->
              <!-- <a class="a a3">
                <img src="../assets/img/icon-16.png" />
              </a> -->
            </div>
            <!-- logo -->
            <a href="/" class="logo alltime">
              <img src="../assets/img/logo.png" />
              <img src="../assets/img/logo-w.png" />
            </a>
          </div>
        </div>
        <div class="hbtm">
          <div class="a alltime">
            <div
              class="pointer"
              @click="handleClick(0)"
              @mouseenter="handleAEnter(0)"
              @mouseleave="hanleLeave(0)"
            >
              <span><s></s>首页</span>
            </div>
            <div
              class="pointer"
              @click="handleClick(1)"
              @mouseenter="handleAEnter(1)"
              @mouseleave="hanleLeave(1)"
            >
              <span :class="{ acti: curIndex == 1 }"><s></s>品牌探索</span>
            </div>
            <div
              class="pointer"
              @click="handleClick(2)"
              @mouseenter="handleAEnter(2)"
              @mouseleave="hanleLeave(2)"
            >
              <span :class="{ acti: curIndex == 2 }"><s></s>全线产品</span>
            </div>
            <div
              class="pointer"
              @click="handleClick(3)"
              @mouseenter="handleAEnter(3)"
              @mouseleave="hanleLeave(3)"
            >
              <span :class="{ acti: curIndex == 3 }"><s></s>走进珮肌</span>
            </div>
            <div
              class="pointer"
              @click="handleClick(4)"
              @mouseenter="handleAEnter(4)"
              @mouseleave="hanleLeave(4)"
            >
              <span :class="{ acti: curIndex == 4 }"><s></s>客户服务</span>
            </div>
            <div
              style="position: relative"
              class="pointer"
              @mouseenter="handleAEnter(5)"
              @mouseleave="hanleLeave(5)"
            >
              <span :class="{ acti: curIndex == 5 }">即刻选购</span>
              <div class="buyTip">
                <a href="buy" @click="handleClick(5)"><s></s>在线选购</a>
                <a href="shopMap" @click="handleClick(5)"><s></s>线下选购</a>
              </div>
            </div>
          </div>
          <!-- 下拉菜单 -->
          <!-- @mouseenter="handleHEnter" @mouseleave="hanleHLeave" -->
          <div class="hbtm2">
            <div class="box3">
              <dl class="dl1">
                <dd
                  class="dd"
                  style="
                    height: 0;
                    margin-top: 0px;
                    padding-bottom: 0px;
                    margin-bottom: 0px;
                    padding-top: 0px;
                  "
                  @mouseenter="handleAEnter(1)"
                  @mouseleave="hanleLeave(1)"
                >
                  <!-- :class="{ enter: enterIndex == 1 }" -->
                  <div class="dox">
                    <div class="dlt">
                      <div class="dtt fbd">
                        <a href="about" @click="handleTopClick(1)">关于珮肌</a>
                      </div>
                      <div class="ax">
                        <a
                          href="about#point0"
                          :class="{ act: leftIndex == 0 }"
                          @mouseenter="leftIndex = 0"
                          @mouseleave="leftIndex = 'a'"
                          @click="handleTopClick(1)"
                          >品牌故事</a
                        >
                        <a
                          href="about#point1"
                          :class="{ act: leftIndex == 1 }"
                          @mouseenter="leftIndex = 1"
                          @mouseleave="leftIndex = 'a'"
                          @click="handleTopClick(1)"
                          >发展历程</a
                        >
                        <!-- <a
                          href="about#point2"
                          :class="{ act: leftIndex == 2 }"
                          @mouseenter="leftIndex = 2"
                          @mouseleave="leftIndex = 'a'"
                          @click="handleTopClick(1)"
                          >明星代言</a
                        > -->
                        <a
                          href="about#point3"
                          :class="{ act: leftIndex == 3 }"
                          @mouseenter="leftIndex = 3"
                          @mouseleave="leftIndex = 'a'"
                          @click="handleTopClick(1)"
                          >研发实力</a
                        >
                        <a
                          href="about#point4"
                          :class="{ act: leftIndex == 4 }"
                          @mouseenter="leftIndex = 4"
                          @mouseleave="leftIndex = 'a'"
                          @click="handleTopClick(1)"
                          >权威认证</a
                        >
                        <a
                          href="about#point5"
                          :class="{ act: leftIndex == 5 }"
                          @mouseenter="leftIndex = 5"
                          @mouseleave="leftIndex = 'a'"
                          @click="handleTopClick(1)"
                          >品牌荣誉</a
                        >
                      </div>
                    </div>
                    <div class="drt">
                      <div class="con">
                        <div class="c">
                          <div class="cs">
                            <div class="cl">
                              <img src="../assets/img/head.jpg" alt="" />
                            </div>
                            <div class="cr">
                              <div class="ri">
                                <img src="../assets/img/about.jpg" alt="" />
                              </div>
                              <div class="r1">
                                冻结时光痕迹，唤醒嘭弹原力。珮肌Paige
                                Luminous玉如意美容仪，助力全球女性打造轻松持久的护肤习惯，延续“美”的力量。
                              </div>
                              <a
                                class="more alltime pointer"
                                href="about"
                                @click="handleTopClick(1)"
                              >
                                <s></s>
                                <span>了解更多</span>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </dd>
                <dd
                  class="dd alltime"
                  @mouseenter="handleAEnter(2)"
                  @mouseleave="hanleLeave(2)"
                  style="
                    height: 0;
                    margin-top: 0px;
                    padding-bottom: 0px;
                    margin-bottom: 0px;
                  "
                >
                  <div class="dox2">
                    <div class="dlt">
                      <div class="dtt fbd">
                        <a
                          href="javascript:void(0);"
                          @mouseenter="handleLeftEnter(0, '')"
                          @click="handleTopClick(2)"
                          >全线产品</a
                        >
                      </div>
                      <div class="ax">
                        <a
                          v-for="(item, index) in allType"
                          :key="index"
                          href="javascript:void(0);"
                          :class="{ act: leftProIndex == index + 1 }"
                          @mouseenter="
                            handleLeftEnter(index + 1, item.productType)
                          "
                          @click="handleTopClick(2)"
                          >{{ item.productType }}</a
                        >
                        <!-- <a
                          href=""
                          :class="{ act: leftProIndex == 2 }"
                          @mouseenter="handleLeftEnter(2)"
                          @click="handleTopClick(2)"
                          >护肤品系列</a
                        > -->
                        <!-- <a
                        href="javascript:void(0);"
                          :class="{ act: leftProIndex == 3 }"
                          @mouseenter="handleLeftEnter(3)"
                          @click="handleTopClick(2)"
                          >护肤品</a
                        > -->
                        <!-- <a href="buy" :class="{ 'act': leftProIndex == 3 }" @mouseenter="leftProIndex = 3"
                          @mouseleave="leftProIndex = 'a'">护肤系列</a> -->
                      </div>
                    </div>
                    <div class="chap i2">
                      <div
                        class="leftBtn pointer"
                        v-if="list.length > 3"
                        @click="scrollToLeft"
                      >
                        <img src="../assets/img/left-arrr.png" alt="" />
                      </div>
                      <ul class="ul1 alltime" ref="scrollContainer">
                        <li v-for="(item, index) in list" :key="index">
                          <a
                            href="javascript:void(0);"
                            class="img"
                            @click="handleTopClick(2), handleDetail(item)"
                          >
                            <img :src="item.productMainPicture" alt="" />
                          </a>
                          <div class="txt f_14">
                            <div class="ltc">{{ item.productName }}</div>
                            <div class="ltt">{{ item.productSubName }}</div>

                            <a
                              href="javascript:void(0);"
                              class="more alltime pointer"
                              @click="handleTopClick(2), handleDetail(item)"
                            >
                              <s></s>
                              <span>了解更多</span>
                            </a>
                          </div>
                        </li>

                        <!-- <li v-if="leftProIndex==0 || leftProIndex=='a'">
                          <div class="img">
                            <img src="../assets/img/pro2.png" alt="" />
                          </div>
                          <div class="txt">
                            <div class="ltc">玉如意美容仪</div>
                            <div class="ltt">太空灰</div>

                            <a href="javascript:void(0);" class="more alltime pointer" @click="handleTopClick(2)">
                              <s></s>
                              <span>了解更多</span>
                            </a>
                          </div>
                        </li>

                        <li v-if="leftProIndex==1 || leftProIndex=='a'">
                          <div class="img">
                            <img src="../assets/img/pro1.png" alt="" />
                          </div>
                          <div class="txt">
                            <div class="ltc">金箔水润凝胶</div>
                            <div class="ltt">官配CP</div>

                            <a href="javascript:void(0);" class="more alltime pointer" @click="handleTopClick(2)">
                              <s></s>
                              <span>了解更多</span>
                            </a>
                          </div>
                        </li> -->
                      </ul>
                      <div
                        class="rightBtn pointer"
                        v-if="list.length > 3"
                        @click="scrollToRight"
                      >
                        <img src="../assets/img/right-arr.png" alt="" />
                      </div>
                    </div>
                  </div>
                </dd>
                <dd
                  class="dd alltime"
                  @mouseenter="handleAEnter(4)"
                  @mouseleave="hanleLeave(4)"
                  style="
                    height: 0;
                    margin-top: 0px;
                    padding-bottom: 0px;
                    margin-bottom: 0px;
                  "
                >
                  <div class="box-s">
                    <a
                      class="item alltime3"
                      href="afterSale"
                      @click="handleClick(4)"
                    >
                      <div class="ms">
                        <s></s>
                        <img src="../assets/img/service-b-4.png" alt="" />
                        <img src="../assets/img/service-4.png" alt="" />
                      </div>
                      <div class="nt f_12">售后服务</div>
                      <!-- <div class="ns f_12">产品信息登记，查看产品保养日期</div> -->
                    </a>

                    <a class="item alltime3" href="use" @click="handleClick(4)">
                      <div class="ms">
                        <s></s>
                        <img src="../assets/img/service-b-3.png" alt="" />
                        <img src="../assets/img/service-3.png" alt="" />
                      </div>
                      <div class="nt f_12">使用教程</div>
                      <!-- <div class="ns f_12">教你如何正确使用美容仪护肤</div> -->
                    </a>

                    <a
                      class="item alltime3"
                      href="service#point10"
                      @click="handleClick(4)"
                    >
                      <div class="ms">
                        <s></s>
                        <img src="../assets/img/service-b-2.png" alt="" />
                        <img src="../assets/img/service-2.png" alt="" />
                      </div>
                      <div class="nt f_12">常见问题</div>
                      <!-- <div class="ns f_12">日常疑问和产品使用技巧</div> -->
                    </a>

                    <a
                      class="item alltime3"
                      href="contact"
                      @click="handleClick(4)"
                    >
                      <div class="ms">
                        <s></s>
                        <img src="../assets/img/service-b-1.png" alt="" />
                        <img src="../assets/img/service-1.png" alt="" />
                      </div>
                      <div class="nt f_12">联系我们</div>
                      <!-- <div class="ns f_12">有任何问题请联系我们</div> -->
                    </a>
                  </div>
                </dd>
              </dl>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs, onMounted, watch, ref } from "vue";
import { useRouter } from "vue-router"; //路由
import { WOW } from "wowjs";
import { products, allProductTypes } from "../api";
export default {
  name: "pcHead",
  props: {
    isBg: Boolean,
    isblackBg: Boolean,
  },
  setup() {
    const router = useRouter(); // 路由
    var timer = null;
    const data = reactive({
      enterIndex: "a",
      leftIndex: "a",
      leftProIndex: "a",
      padIndex: "a",
      curIndex: sessionStorage.getItem("store"),
      res: "",
      list: [],
      allType: [],
    });
    watch(data.enterIndex, (newX) => {
      if (newX != "a") {
        data.padIndex = newX;
      }
    });
    const getData = () => {
      allProductTypes().then((res) => {
        data.allType = res.data;
        products({ type: "" }).then((res) => {
          // data.res = res.data;
          data.list = res.data;
          // console.log(data.list);
        });
      });
    };
    onMounted(async () => {
      new WOW({
        boxClass: "wow", // 需要执行动画的元素的 class
        animateClass: "animated", //animation.css 动画的 class
        offset: 100, // 距离可视区域多少开始执行动画
        mobile: false, // 是否在移动设备上执行动画
        live: true, // 异步加载的内容是否有效
        callback: function (box) {
          console.log(box);
          box.classList.add("wow1");
        },
      }).init();

      window.addEventListener("setItemEvent", function (e) {
        // 这里的info是我存入localstorage的key值
        if (e.key === "store") {
          console.log("##", e.newValue);
          data.curIndex = e.newValue;
          // 如果发生变化的e.key=想要监听的key值，则执行需要的操作
          // e.newValue是变化后的值
        }
      });

      getData();
    });
    const handleTopClick = (index) => {
      sessionStorage.setItem("store", index);
    };

    const handleDetail = (item) => {
      if (item.id == 1 || item.id == 2) {
        router.push("/introduce");
      } else {
        router.push({
          path: "/productDetail",
          query: {
            details: item.details,
          },
        });
      }
    };
    const handleLeftEnter = (index, productType) => {
      data.leftProIndex = index;
      products({ type: productType }).then((res) => {
        // data.res = res.data;
        data.list = res.data;
        // console.log(data.list);
      });
      // if (index == 0) {
      //   data.list = data.res.allProduct;
      // }

      // if (index == 1) {
      //   data.list = data.res.succession;
      // }

      // if (index == 2) {
      //   data.list = data.res.accessories;
      // }

      // if (index == 3) {
      //   data.list = data.res.skinCare;
      // }
    };
    const handleAEnter = (index) => {
      data.enterIndex = index;

      if (index == 2) {
        clearTimeout(timer);
        const card = document.querySelector(".dd:nth-child(2)");
        card.style.height = "450px";
      }

      if (index == 1) {
        clearTimeout(timer);
        const card = document.querySelector(".dd:first-child");
        card.style.height = "368px";
      }

      if (index == 4) {
        clearTimeout(timer);
        const card = document.querySelector(".dd:last-child");
        card.style.height = "218px";
      }
      if (index == 5) {
        clearTimeout(timer);
        const card = document.querySelector(".buyTip");
        card.style.opacity = 1;
      }
    };

    const hanleLeave = (index) => {
      data.enterIndex = "a";
      const card1 = document.querySelector(".dd:first-child");
      const card2 = document.querySelector(".dd:nth-child(2)");
      const card3 = document.querySelector(".dd:last-child");
      card1.style.height = "0";
      card2.style.height = "0";
      card3.style.height = "0";

      if (index == 1 || index == 2 || index == 4) {
        timer = setTimeout(function () {
          const box = document.querySelector(".hbtm2");
          box.style.paddingTop = "0px";
        }, 300);
      } else {
        const box = document.querySelector(".hbtm2");
        box.style.paddingTop = "0px";
      }

      if (index == 5) {
        clearTimeout(timer);
        const card = document.querySelector(".buyTip");
        card.style.opacity = 0;
      }
    };
    const handleClick = (index) => {
      // data.curIndex = index;
      sessionStorage.setItem("store", index);
      console.log(index);
      // 根据导航栏索引获取对应的路由路径
      switch (index) {
        case 0:
          router.push("/");
          break;
        case 1:
          router.push("/about");
          break;
        case 2:
          // router.push("/buy");
          break;
        case 3:
          router.push("/paigeNews");
          break;
        case 4:
          router.push("/service");
          break;
        default:
          router.push("/buy");
          break;
      }
    };
    const scrollContainer = ref(null);

    const scrollToRight = () => {
      if (scrollContainer.value) {
        scrollContainer.value.scrollBy({
          left: 200, // 滚动的距离，可以根据需要调整
          behavior: "smooth", // 平滑滚动
        });
      }
    };
    const scrollToLeft = () => {
      if (scrollContainer.value) {
        scrollContainer.value.scrollBy({
          left: -200, // 滚动的距离，可以根据需要调整
          behavior: "smooth", // 平滑滚动
        });
      }
    };
    return {
      handleAEnter,
      hanleLeave,
      handleClick,
      handleTopClick,
      handleLeftEnter,
      scrollToRight,
      scrollContainer,
      ...toRefs(data),
      handleDetail,
      scrollToLeft,
    };
  },
};
</script>

<style scoped lang="scss">
.leftBtn {
  position: absolute;
  width: 20px;
  height: 230px;
  left: 0;
  bottom: 30px;
}
.rightBtn {
  position: absolute;
  width: 20px;
  height: 230px;
  right: 0;
  bottom: 30px;
}
.nav {
  position: fixed;
  width: 100%;
  height: 100px;
  z-index: 100;
  left: 0;
  top: 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.box {
  width: 94%;
  margin: 0 auto;
  position: relative;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  transition: all 0.5s;
  box-sizing: border-box;
}

.head {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 15;
}

.head .hedad_pc {
  position: fixed;
  width: 100%;
  // background: #000;
  -webkit-transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
  -moz-transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
  transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
  // padding-bottom: 10px;
}

.head .hedad_pc.bei {
  background: #000;
}

.head .hedad_pc .htop {
  margin: 30px 0;
}

.head .hedad_pc .htop .hlt {
  float: left;
  display: flex;
  height: 30px;
  align-items: center;
  align-content: center;
  position: relative;
  cursor: pointer;
}

.head .hedad_pc .htop .hlt .a img {
  display: block;
  width: 18px;
}

.head .hedad_pc .htop .hrt form {
  display: flex;
  align-items: center;
  margin-right: 10px;
}

.head .hedad_pc .htop .hrt input {
  display: block;
  width: 0px;
  height: 30px;
  background: transparent;
  border: none;
  outline: none;
  color: #fff;
}

.head .hedad_pc .htop .hrt .bd {
  width: 0;
  height: 40px;
  border-bottom: 1px solid #c4946c;
}

.head .hedad_pc .htop .hrt .ss {
  width: 18px;
  height: 18px;
  // background: url("../assets/img/icon-2.png") center no-repeat;
  color: #fff;
}

.head .hedad_pc .htop .hrt .wz {
  width: 18px;
  color: #fff;
  overflow: hidden;
  white-space: nowrap;
}

.head .hedad_pc .htop .hrt s {
  display: block;
  width: 0%;
  height: 1px;
  background: #000;
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 1;
}

.head .hedad_pc .htop .hrt {
  float: right;
  display: flex;
  height: 30px;
  align-items: center;
  align-content: center;
  position: relative;
  cursor: pointer;
  line-height: 30px;
}

.head .hedad_pc .htop .hrt .a {
  display: block;
  width: 30px;
  position: relative;
  padding: 7px;
  margin-left: 10px;
}

.head .hedad_pc .htop .hrt .a img {
  display: block;
  width: 18px;
}

.head .hedad_pc .htop .hrt .a img:nth-child(2),
.head .hedad_pc .htop .hrt .a img:nth-child(3) {
  position: absolute;
  left: 6px;
  top: 6px;
  z-index: 2;
  opacity: 0;
}

.head .hedad_pc .htop .hlt .yy {
  font-family: Arial;
  margin-left: 6px;
  font-size: 15px;
}

.head .hedad_pc .htop .hlt .yy .en {
  color: #fff;
  font-size: 14px;
}

.head .hedad_pc .htop .hlt .yy .yox {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 3;
  background: rgba(33, 48, 61, 0.5);
  pointer-events: none;
  opacity: 0;
}

.head .hedad_pc .htop .hlt .yy .a2 {
  // width: 105px;
  // height: 115px;
  position: absolute;
  top: 0px;
  left: 0px;
  padding-top: 40px;
  z-index: 5;
  pointer-events: none;
  visibility: hidden;
  opacity: 0;
}

.head .hedad_pc .htop .hlt .yy .a2 .aox {
  background: #fff;
  padding: 0 10px 20px 10px;
  width: 100px;
  background-image: url("../assets/img/btn-more-g.jpg");
  background-position: center;
  background-size: 100%;
  // height: 160px;
}

.head .hedad_pc .htop .hlt .yy .a2 a {
  font-size: 14px;
  display: block;
  line-height: 1;
  padding-top: 20px;
}

.head .hedad_pc .htop .hlt .yy .a2 a div {
  display: inline-block;
  position: relative;
  color: #222;
}

.head .hedad_pc .htop .hlt .yy .a2 a span {
  margin: 0 5px;
}

.head .hedad_pc .htop .hlt .yy .a2 a div s {
  display: block;
  width: 0%;
  height: 1px;
  background: #c4946c;
  position: absolute;
  left: 0;
  bottom: -5px;
  z-index: 2;
}

.head .hedad_pc .htop .hlt:hover .yox {
  visibility: visible;
  opacity: 1;
}

.head .hedad_pc .htop .hlt:hover .a2 {
  visibility: visible;
  opacity: 1;
  pointer-events: visible;
}

.head .hedad_pc .htop .hlt .yy .a2 a:hover div s {
  width: 100%;
}

// .head .hedad_pc .htop .hlt .yy .a2 a:hover div {
//   color: #c4946c;
// }

.head .hedad_pc .htop .logo {
  height: 30px;
  position: absolute;
  top: 0;
  left: calc(50% - 104px);
}

.head .hedad_pc .htop .logo img {
  display: block;
  height: 100%;
}

.head .hedad_pc .htop .logo img:nth-child(2) {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
  opacity: 0;
}

.head .hedad_pc .hbtm {
  // width: 610px;
  margin: 0 auto;
}

.head .hedad_pc .hbtm .a {
  display: flex;
  justify-content: center;
}

.head .hedad_pc .hbtm .a .acti {
  border-bottom: 1px solid;
  border-image: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0) 0%,
      #fed8be 50%,
      rgba(255, 255, 255, 0) 99%
    )
    2 2 2 2;
}

.head .hedad_pc .hbtm .a div {
  // display: block;
  margin: 0 25px;
  height: 30px;
}

.head .hedad_pc .hbtm .a div span {
  display: inline-block;
  position: relative;
  color: #fff;
  height: 18px;
  font-size: 14px;
}

.head .hedad_pc .hbtm .a div span s {
  position: absolute;
  left: 0;
  top: 18px;
  z-index: 1;
  width: 0%;
  background: #c4946c;
  height: 1px;
}

.head .actii {
  transform: translateY(-120px);
  -webkit-transform: translateY(-180px);
}

.head .bg {
  background: #000;
}

.head .notBg {
  background: transparent;
}

.head .hedad_pc:hover,
.head .hedad_pc.act {
  background: #000;
}

.head .hedad_pc:hover .htop .logo img:nth-child(1),
.head .hedad_pc.act .htop .logo img:nth-child(1) {
  opacity: 0;
}

.head .hedad_pc:hover .htop .hrt .a img:nth-child(2),
.head .hedad_pc.act .htop .hrt .a img:nth-child(2),
.head .hedad_pc:hover .htop .logo img:nth-child(2),
.head .hedad_pc.act .htop .logo img:nth-child(2) {
  opacity: 1;
}

.head .hedad_pc:hover .htop .hrt .yy .en,
.head .hedad_pc.act .htop .hrt .yy .en {
  color: #fff;
}

.head .hedad_pc:hover .hbtm .a a span,
.head .hedad_pc.act .hbtm .a a span {
  color: #fff;
}

.head .hedad_pc:hover .htop .hrt .ss,
.head .hedad_pc.act .htop .hrt .ss {
  // background: url("../assets/img/icon-2.png") center no-repeat;
  // background-color: rgba(255, 255, 255, 0.1);
}

.head .hedad_pc:hover .htop .hrt .wz,
.head .hedad_pc.act .htop .hrt .wz {
  color: #fff;
}

.head .hedad_pc.bai,
.head .hedad_pc.bai2 {
  background: #fff;
}

.head .hedad_pc.bai .head_con {
  background: #fff;
}

.head:hover .head_con {
  background: #fff;
}

.head .hedad_pc.bai .htop .logo img:nth-child(1),
.head .hedad_pc.bai .htop .hrt .a img:nth-child(1),
.head .hedad_pc.bai2 .htop .logo img:nth-child(1),
.head .hedad_pc.bai2 .htop .hrt .a img:nth-child(1) {
  opacity: 0;
}

.head .hedad_pc.bai .htop .logo img:nth-child(2),
.head .hedad_pc.bai .htop .hrt .a img:nth-child(2),
.head .hedad_pc.bai2 .htop .logo img:nth-child(2),
.head .hedad_pc.bai2 .htop .hrt .a img:nth-child(2) {
  opacity: 1;
}

.head .hedad_pc.bai .htop .hrt .yy .en,
.head .hedad_pc.bai2 .htop .hrt .yy .en {
  color: #fff;
}

.head .hedad_pc.bai .hbtm .a a span,
.head .hedad_pc.bai2 .hbtm .a a span {
  color: #fff;
}

.head .hedad_pc.bai .htop .hrt .ss,
.head .hedad_pc.bai2 .htop .hrt .ss {
  // background: url("../assets/img/icon-2.png") center no-repeat;
}

.head .hedad_pc.bai .htop .hrt .wz,
.head .hedad_pc.bai2 .htop .hrt .wz {
  color: #fff;
}

.head .hedad_pc .htop form:hover .bd {
  width: 200px;
}

.head .hedad_pc .htop form:hover input {
  width: 200px;
  padding: 0 10px;
}

.head .hedad_pc .htop .hrt:hover s {
  width: 100%;
}

.head .hedad_pc .htop form:hover .wz {
  width: 0px;
}

.head .hedad_pc .htop .hrt .yy:hover {
  color: #c4946c;
}

.head .hedad_pc .hbtm .a div:hover span {
  color: #c4946c;
}

.head .hedad_pc .hbtm .a div:hover span s {
  width: 100%;
}

.Sign2 {
  display: none;
}

.Sign2 s {
  position: fixed;
  left: 0;
  top: 120px;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.45);
  z-index: 1;
  cursor: pointer;
}

.Sign2 .con {
  position: absolute;
  width: 285px;
  text-align: center;
  right: -10px;
  top: 100px;
  color: #fff;
  z-index: 1;
}

.Sign2 .con .con1 {
}

.Sign2 .con .con1 a {
  color: #fff;
  height: 45px;
  line-height: 45px;
  display: block;
  background: #000000;
  position: relative;
}

.Sign2 .con .con1::after {
  content: "";
  width: 14px;
  height: 14px;
  background: #000000;
  position: absolute;
  left: calc(50% - 7px);
  transform: rotate(45deg);
  top: -3px;
  z-index: 0;
}

.Sign2 .con .con1 a span {
  position: relative;
  z-index: 1;
  display: block;
  z-index: 2;
}

.Sign2 .con .con1 a::after {
  width: 100%;
  height: 0%;
  position: absolute;
  left: 0;
  bottom: 0;
  background: #c4946c;
  content: "";
  transition: all 0.4s;
  z-index: 1;
}

.Sign2 .con .con1 a:hover::after {
  height: 100%;
  transition: all 0.4s;
}

.Sign2 .con .con2 {
  text-align: center;
  color: #fff;
  margin-top: 12px;
}

// .head .hedad_pc .htop .hrt a.a:hover img:nth-child(1),
// .head .hedad_pc .htop .hrt a.a:hover img:nth-child(2) {
//   opacity: 0;
// }

.head .hedad_pc .htop .hrt a.a:hover img:nth-child(3) {
  opacity: 1;
}

.head .hedad_pc.bei .htop .hrt .a img:nth-child(1),
.head .hedad_pc.bei .htop .hrt .a img:nth-child(1),
.head .hedad_pc.bei .htop .logo img:nth-child(1),
.head .hedad_pc.bei .htop .logo img:nth-child(1) {
  opacity: 0;
}

.head .hedad_pc.bei .htop .hrt .a img:nth-child(2),
.head .hedad_pc.bei .htop .hrt .a img:nth-child(2),
.head .hedad_pc.bei .htop .logo img:nth-child(2),
.head .hedad_pc.bei .htop .logo img:nth-child(2) {
  opacity: 1;
}

.head .hedad_pc.bei .hbtm .a a span {
  color: #fff;
}

.head .hedad_pc.bei .htop .hrt .wz {
  color: #fff;
}

.head .hedad_pc.bei .htop .hrt .ss {
  // background: url("../assets/img/icon-2.png") center no-repeat;
}

// 下拉
.head .hedad_pc .hbtm .hbtm2 {
  position: fixed;
  width: 100%;
  background: rgba(0, 0, 0, 0.8);
  // background: transparent;
  left: 0;
  top: 120px;
  z-index: 5;
  display: flex;
  backdrop-filter: blur(10px);
  overflow: hidden;
}

.box3 {
  width: 960px;
  margin: 0 auto;
  position: relative;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  transition: all 0.5s;
  box-sizing: border-box;
}

.head .hedad_pc .hbtm .hbtm2 .dl1 dd {
  overflow: hidden;
}

.head .hedad_pc .hbtm .hbtm2 .dl1 .dd {
  height: 0;
  transition: height 0.3s linear;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.head .hedad_pc .hbtm .hbtm2 .dl1 .dd .dox {
  display: flex;
}

.head .hedad_pc .hbtm .hbtm2 .dl1 .dd .dox .dlt a,
.head .hedad_pc .hbtm .hbtm2 .dl1 .dd .dox2 .dlt a {
  display: block;
  color: #fff;
  margin-top: 25px;
  font-size: 14px;
}

.head .hedad_pc .hbtm .hbtm2 .dl1 .dd .dox2 .dlt .dtt a:first-child {
  margin-top: 0px;
}

.head .hedad_pc .hbtm .hbtm2 .dl1 .dd .dox .dlt .dtt a {
  margin-top: 0;
}

.head .hedad_pc .hbtm .hbtm2 .dl1 .dd .dox .dlt a.act,
.head .hedad_pc .hbtm .hbtm2 .dl1 .dd .dox2 .dlt a.act {
  color: #c4946c;
}

.head .hedad_pc .hbtm .hbtm2 .dl1 .dd .dox .drt {
  // width: 710px;
  position: relative;
}

.head .hedad_pc .hbtm .hbtm2 .dl1 .dd .dox .drt .ul1 {
  display: flex;
  height: 260px;
  position: relative;
}

.head .hedad_pc .hbtm .hbtm2 .dl1 .dd .dox .drt .ul1 li {
  width: 200px;
  margin-right: 55px;
  text-align: center;
  position: relative;
  -webkit-transition: all 0.7s cubic-bezier(0.4, 0, 0.2, 1);
  -moz-transition: all 0.7s cubic-bezier(0.4, 0, 0.2, 1);
  transition: all 0.7s cubic-bezier(0.4, 0, 0.2, 1);
  opacity: 1;
}

.head .hedad_pc .hbtm .hbtm2 .dl1 .dd .dox .drt .ul1 li:last-child {
  margin-right: 0;
}

.head .hedad_pc .hbtm .hbtm2 .dl1 .dd .dox .drt .ul1 li .img {
  overflow: hidden;
  display: block;
  width: 100px;
  height: 190px;
  position: relative;
}

.head .hedad_pc .hbtm .hbtm2 .dl1 .dd .dox .drt .ul1 li .img img {
  display: block;
  width: 100%;
}

.head .hedad_pc .hbtm .hbtm2 .dl1 .dd .dox .drt .ul1 li .txt {
  padding-top: 25px;
}

.head .hedad_pc .hbtm .hbtm2 .dl1 .dd .dox .drt .ul1 li .txt .ltc {
  display: block;
  color: #fff;
  margin-top: 10px;
  font-size: 14px;
}

.head .hedad_pc .hbtm .hbtm2 .dl1 .dd .dox .drt .ul1 li:nth-child(2) {
  transition-delay: 0.1s;
  -webkit-transition-delay: 0.1s;
}

.head .hedad_pc .hbtm .hbtm2 .dl1 .dd .dox .drt .ul1 li:nth-child(3) {
  transition-delay: 0.2s;
  -webkit-transition-delay: 0.2s;
}

.head .hedad_pc .hbtm .hbtm2 .dl1 .dd .dox .drt .ul1.act li {
  top: 100%;
  -webkit-transition: all 0s cubic-bezier(0.4, 0, 0.2, 1);
  -moz-transition: all 0s cubic-bezier(0.4, 0, 0.2, 1);
  transition: all 0s cubic-bezier(0.4, 0, 0.2, 1);
  opacity: 0;
}

.head .hedad_pc .hbtm .hbtm2 .dl1 .dd .dox .drt .jj {
  width: 100%;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 3;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.head .hedad_pc .hbtm .hbtm2 .dl1 .dd .dox .drt .jj .img {
  width: 260px;
}

.head .hedad_pc .hbtm .hbtm2 .dl1 .dd .dox .drt .jj .txt {
  width: 400px;
}

.head .hedad_pc .hbtm .hbtm2 .dl1 .dd .dox .drt .jj .txt .tc {
  text-align: justify;
  line-height: 24px;
  color: #fff;
}

.head .hedad_pc .hbtm .hbtm2 .dl1 .dd .dox .drt .jj .txt .tc s {
  display: block;
  width: 100%;
  height: 15px;
}

.head .hedad_pc .hbtm .hbtm2 .dl1 .dd .dox .drt .jj .txt .more {
  display: block;
  width: 160px;
  line-height: 50px;
  background: #e7004c;
  text-align: center;
  position: absolute;
  overflow: hidden;
  z-index: 3;
  bottom: 0;
}

.head .hedad_pc .hbtm .hbtm2 .dl1 .dd .dox .drt .jj .txt .more span {
  position: relative;
  z-index: 3;
  color: #fff;
  letter-spacing: 2px;
}

.head .hedad_pc .hbtm .hbtm2 .dl1 .dd .dox .drt .jj .txt .more s {
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
  background: #000;
  position: absolute;
}

.head .hedad_pc .hbtm .hbtm2 .dl1 .dd .dox .drt .jj .txt .more:hover s {
  width: 0%;
}

.head .hedad_pc .hbtm .hbtm2 .dl1 .dd .dox .drt .jj .txt .more:hover span {
  color: #fff;
}

// .head .hedad_pc .hbtm .hbtm2 .dl1 .dd .dox .drt .con .c {
//   display: none;
// }

// .head .hedad_pc .hbtm .hbtm2 .dl1 .dd .dox .drt .con .c.act {
//   display: block;
// }

.head .hedad_pc .hbtm .hbtm2 .dl1 .dd .dox .drt .con .cs {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  position: relative;
}

.head .hedad_pc .hbtm .hbtm2 .dl1 .dd .dox .drt .con .cs .cl {
  width: 252px;
  height: 252px;
  margin: 0 60px;
}

.head .hedad_pc .hbtm .hbtm2 .dl1 .dd .dox .drt .con .cs .cl img {
  width: 252px;
  height: 252px;
  object-fit: cover;
  border-radius: 10px;
}

.head .hedad_pc .hbtm .hbtm2 .dl1 .dd .dox .drt .con .cs .cr {
  width: 310px;
  height: 252px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.head .hedad_pc .hbtm .hbtm2 .dl1 .dd .dox .drt .con .cs .cr img {
  width: 201px;
}

// .head .hedad_pc .hbtm .hbtm2 .dl1 .dd .dox .drt .con .cs .cr img {
//   width: 241px;
// }

.head .hedad_pc .hbtm .hbtm2 .dl1 .dd .dox .drt .con .cs .cr .r1 {
  text-align: justify;
  color: #fff;
  font-size: 12px;
  line-height: 20px;
  width: 390px;
  // margin: 20px 0;
}

.head .hedad_pc .hbtm .hbtm2 .dl1 .dd .dox .drt .con .cs .cr .r1 s {
  display: block;
  width: 100%;
  height: 15px;
}

.head .hedad_pc .hbtm .hbtm2 .dl1 .dd .dox .drt .con .cs .cr .more {
  display: block;
  width: 160px;
  text-align: center;
  overflow: hidden;
  z-index: 3;
  height: 47px;
  line-height: 47px;
  background-image: url("../assets/img/btn-more.png");
  background-position: top left;
  background-size: 100%;
  position: relative;
}

.head .hedad_pc .hbtm .hbtm2 .dl1 .dd .dox .drt .con .cs .cr .more span {
  position: relative;
  z-index: 3;
  color: #fff;
  letter-spacing: 2px;
}

.head .hedad_pc .hbtm .hbtm2 .dl1 .dd .dox .drt .con .cs .cr .more s {
  display: block;
  width: 0%;
  height: 100%;
  bottom: 0;
  left: 0;
  z-index: 1;
  position: absolute;
  background-image: url("../assets/img/btn-more-g.jpg");
  background-position: top;
  background-size: 100% 101%;
  font-size: 0;
}

.head .hedad_pc .hbtm .hbtm2 .dl1 .dd .dox .drt .con .cs .cr .more:hover s {
  width: 100%;
}

.i2 {
  z-index: 2;
  height: 367px;
  position: relative;
}

.i2 .ul1 {
  margin: 0 60px;
  width: 700px;
  // height:580px;
  display: flex;
  justify-content: space-evenly;
  overflow-x: scroll;
}

.i2 .ul1 li {
  width: 30%;
  // margin-right: 60px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
}

.i2 .ul1 li .img {
  display: flex;
  justify-content: center;
  height: 230px;
  margin-bottom: 30px;
}

.i2 .ul1 li .img img {
  height: 100%;
  object-fit: cover;
  transform: scale(1.000000000000001);
  -webkit-transform: scale(1.000000000000001);
}

.i2 .ul1 li .txt {
  color: #000;
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.i2 .ul1 li .txt .ltc {
  letter-spacing: 1.3px;
  color: #fff;
  font-size: 14px;
}

.i2 .ul1 li .txt .ltt {
  margin-top: 10px;
  letter-spacing: 1.2px;
  color: #fff;
  font-size: 14px;
}

.i2 .ul1 li .txt .more {
  display: block;
  width: 174px;
  height: 49px;
  text-align: center;
  position: relative;
  z-index: 3;
  font-size: 14px;
  margin-top: 20px;
  background-image: url("../assets/img/btn-more.png");
  background-position: center;
  background-size: contain;
}

.i2 .ul1 li .txt .more span {
  position: relative;
  z-index: 3;
  color: #fff;
  letter-spacing: 2px;
  line-height: 49px;
}

.i2 .ul1 li .txt .more s {
  display: block;
  width: 0%;
  height: 100%;
  bottom: 0;
  left: 0;
  z-index: 1;
  background: #000;
  position: absolute;
  background-image: url("../assets/img/btn-more-g.jpg");
  background-position: top;
  background-size: 100% 101%;
}

.i2 .ul1 li .txt .more:hover s {
  width: 100%;
}

.i2 .ul1 li .txt .more:hover span {
  color: #fff;
}

.i2 .ul1 li:hover .txt .more {
  opacity: 1;
}

.i2 .ul1 li:hover .img img {
  transform: scale(1.05);
  -webkit-transform: scale(1.05);
}

.head .hedad_pc .hbtm .hbtm2 .dl1 .dd .dox2 {
  display: flex;
  flex-direction: row;
}

.dox2 .dlt .dtt a {
  color: #fff;
}

.box-s {
  display: flex;
  width: 100%;
  color: #fff;
  text-align: center;
  display: flex;
  justify-content: space-between;
}

.item {
  width: 25%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-left: 2px solid;
  /*设置线性渐变*/
  border-image: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 0%,
      #fed8be 50%,
      rgba(255, 255, 255, 0) 99%
    )
    2 2 2 2;

  &:last-child {
    border-right: 2px solid;
    /*设置线性渐变*/
    border-image: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0) 0%,
        #fed8be 50%,
        rgba(255, 255, 255, 0) 99%
      )
      2 2 2 2;
  }

  .nt {
    margin-bottom: 10px;
  }

  color: #fff;
}

.ms {
  width: 70px;
  height: 70px;
  background: url("../assets/img/service-bg-1.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;

  img {
    position: absolute;
    top: 20px;
    left: 20px;
    width: 30px;
    height: 30px;
    object-fit: contain;
  }

  margin-bottom: 20px;
}

.ms s {
  display: block;
  width: 70px;
  height: 100%;
  bottom: 0;
  left: 0;
  background: url("../assets/img/service-bg-2.png");
  position: absolute;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  opacity: 0;
}

.item .ms:hover s {
  opacity: 1;
}

.item .ms img:first-child {
  opacity: 0;
}

.item .ms:hover img:first-child {
  opacity: 1;
}

.item .ms img:last-child {
  opacity: 1;
}

.item .ms:hover img:last-child {
  opacity: 0;
}

.notenter {
  display: none;
  height: 0;
  padding-top: 50px;
  margin-top: 0px;
  padding-bottom: 90px;
  margin-bottom: 0px;
}

.enter {
  display: block;
  height: 100%;
}

.buyTip {
  opacity: 0;
  position: absolute;
  top: 20px;
  left: -30px;
  display: flex;
  flex-direction: column;
  width: 120px;
  align-items: center;
  margin: 0 !important;
  s {
    display: block;
    width: 0%;
    height: 1px;
    background: #fed8be;
    position: absolute;
    left: 25px;
    bottom: 10px;
    z-index: 1;
  }

  a {
    color: #fff;
    background: #000;
    padding: 15px 25px 15px 25px;
    position: relative;
    &:last-child {
      padding-top: 0;
    }
  }
  a:hover {
    color: #fed8be;
  }
  a:hover s {
    width: calc(100% - 50px);
  }
}
</style>
