import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/assets/fonts/fonts.css'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import { dispatchEventStorage } from '@/assets/lib/util'
import animated from 'animate.css'
import 'wowjs/css/libs/animate.css'
import '@/assets/lib/flexible'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import zhCn from 'element-plus/dist/locale/zh-cn.mjs'

const app = createApp(App)
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}
app.use(ElementPlus,{locale:zhCn}).use(animated).use(dispatchEventStorage).use(store).use(router).mount('#app')

// 跳转后返回顶部
router.afterEach(() => {
  window.scrollTo(0,0);
})
