<template>
  <div class="footer alltime" :class="{ pos: position == 1 }">
    <!-- <div class="box">
      <div class="item">
        <s></s>
        <img src="../assets/img/service-4.png" alt="" />
      </div>
    </div> -->
    <div class="box2">
      <div class="ftop">
        <div class="frt">
          <div class="dl1 f_12">
            <div>
              <a href="about" class="fbd" @click="handleTopClick(1)"
                >品牌探索</a
              >
              <a href="about#point0" @click="handleTopClick(1)"
                ><span><s></s> 品牌故事</span></a
              >
              <a href="about#point1" @click="handleTopClick(1)"
                ><span><s></s> 发展历程</span></a
              >
              <!-- <a href="about#point2" @click="handleTopClick(1)"
                ><span><s></s> 明星代言</span></a
              > -->
              <a href="about#point3" @click="handleTopClick(1)"
                ><span><s></s> 研发实力</span></a
              >
              <a href="about#point4" @click="handleTopClick(1)"
                ><span><s></s> 权威认证</span></a
              >
              <a href="about#point5" @click="handleTopClick(1)"
                ><span><s></s> 品牌荣誉</span></a
              >
            </div>
            <div>
              <a href="introduce" class="fbd" @click="handleTopClick(2)">全线产品</a>
              <a href="introduce" @click="handleTopClick(2)"
                ><span><s></s> 美容仪系列</span></a
              >
              <!-- <a href="buy" @click="handleTopClick(2)"
                ><span class="subTitle"><s></s> 玉如意美容仪</span></a
              > -->
              <a href="introduce" @click="handleTopClick(2)"
                ><span><s></s>护肤品系列</span></a
              >
              <!-- <a href="buy" @click="handleTopClick(2)"
                ><span class="subTitle"><s></s> 金箔水润凝胶</span></a
              > -->
              <!-- <a href="buy"><span><s></s> 护肤品</span></a> -->
              <!-- <a href="buy" @click="handleTopClick(2)"
                ><span><s></s> 护肤系列</span></a
              > -->
            </div>

            <div>
              <a href="paigeNews" class="fbd" @click="handleTopClick(3)"
                >走进珮肌</a
              >
              <a href="paigeNews?index=0" @click="handleTopClick(3)"
                ><span><s></s> 品牌资讯</span></a
              >
              <a href="paigeNews?index=1" @click="handleTopClick(3)"
                ><span><s></s> 珮肌美学院</span></a
              >
            </div>
            <div>
              <a href="service" class="fbd" @click="handleTopClick(4)"
                >客户服务</a
              >
              <a href="afterSale" @click="handleTopClick(4)"
                ><span><s></s> 售后服务</span></a
              >
              <a href="service#point10" @click="handleTopClick(4)"
                ><span><s></s> 常见问题</span></a
              >
              <a href="contact" @click="handleTopClick(4)"
                ><span><s></s> 联系我们</span></a
              >
              <a href="use" @click="handleTopClick(4)"
                ><span><s></s> 使用教程</span></a
              >
              <a @click="downloadFile"
                ><span><s></s> 招标文件</span></a
              >
              <a @click="downloadExcel"
                ><span><s></s> 招标表格</span></a
              >
            </div>
            <div>
              <a href="buy" class="fbd" @click="handleTopClick(5)">即刻选购</a>
              <a href="buy" @click="handleTopClick(5)"
                ><span><s></s> 在线选购</span></a
              >
              <a href="shopMap" @click="handleTopClick(5)"
                ><span><s></s> 线下选购</span></a
              >
            </div>
            <!-- <div>
              <s></s>
              <a href="policy">网站条款</a>
              <a href="privacy"><span><s></s> 隐私政策</span></a>
            </div> -->
            <div>
              <a href="" class="fbd">客户服务</a>
              <img class="fttimg" src="../assets//img/icon-8.png" alt="" />
              <div style="margin-top: 20px">客服电话：13302980652</div>
            </div>

            <div>
              <a href="" class="fbd">关注我们</a>
              <div class="a">
                <a
                  href="javascript:void(0);"
                  class="wxa"
                  @mouseenter="handleEnter(1)"
                  @mouseleave="handleLeave(1)"
                >
                  <img src="../assets/img/icon-17.png" />
                </a>
                <div>
                  <img
                    class="weixin alltime3"
                    src="../assets/img/qrweixin.png"
                    alt=""
                  />
                </div>
                <a
                  href="javascript:void(0);"
                  @mouseenter="handleEnter(2)"
                  @mouseleave="handleLeave(2)"
                >
                  <img src="../assets/img/icon-15.png" />
                </a>
                <div>
                  <img
                    class="weibo alltime3"
                    src="../assets/img/qrweibo.png"
                    alt=""
                  />
                </div>
                <a
                  href="https://www.xiaohongshu.com/user/profile/63438910000000001802b1cf"
                  target="_blank"
                >
                  <img src="../assets/img/icon-14.png" />
                </a>
                <a href="https://v.douyin.com/iRX1yvXG/" target="_blank">
                  <img src="../assets/img/icon-13.png" />
                </a>
                <a
                  href="https://www.facebook.com/paigeluminous"
                  target="_blank"
                >
                  <img src="../assets/img/icon-12.png" />
                </a>
                <a
                  href="https://www.instagram.com/paigeluminous/"
                  target="_blank"
                >
                  <img src="../assets/img/icon-11.png" />
                </a>
                <a
                  href="https://www.youtube.com/@PaigeLuminous"
                  target="_blank"
                >
                  <img src="../assets/img/icon-10.png" />
                </a>
              </div>
              <div class="a" style="margin-top: 18px;width:80px" 
              @mouseenter="handleEnter(3)"
                  @mouseleave="handleLeave(3)">
                <a class="aaa fbd"
                  href="https://ms-paige.com/"
                  target="_blank"
                >
                香港官网
                </a>
                <div @click="toPath">
                  <img
                    class="gw alltime3"
                    src="../assets/img/gw.jpg"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="flt">
          <div class="ftt fbd">关注我们</div>
          <div class="a">
            <a href="" target="_blank">
              <img src="../assets/img/icon-17.png" />
            </a>
            <a href="" target="_blank">
              <img src="../assets/img/icon-15.png" />
            </a>
            <a href="" target="_blank">
              <img src="../assets/img/icon-14.png" />
            </a>
            <a href="" target="_blank">
              <img src="../assets/img/icon-13.png" />
            </a>
            <a href="" target="_blank">
              <img src="../assets/img/icon-12.png" />
            </a>
            <a href="" target="_blank">
              <img src="../assets/img/icon-11.png" />
            </a>
            <a href="" target="_blank">
              <img src="../assets/img/icon-10.png" />
            </a>
          </div>
          <div class="ftt fbd">客户服务</div>
          <img class="fttimg" src="../assets//img/icon-8.png" alt="" />
          <div class="ftt" style="margin-top: 10px">官方微信客服</div>
          <div class="ftt">Email: cs@regal-silver.com</div>
        </div> -->
      </div>
      <div
        style="
          display: flex;
          width: 100%;
          justify-content: center;
          flex-direction: column;
          align-items: center;
        "
      >
        <a class="logo"><img src="../assets/img/icon-9.png" alt="" /></a>
        <div class="fbtm">
          <div class="flt">
            <div class="a">
              Copyright © 2023 Paige Luminous. All right reserved.
            </div>
            <!-- <a class="a" href="" target="_blank" rel="nofollow">备案号</a> -->
            <a
              class="a"
              href="https://beian.miit.gov.cn"
              target="_blank"
              rel="nofollow"
            >
              粤ICP备2023151209号
            </a>
            <a
              class="a"
              href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44030002002210"
              target="_blank"
              rel="nofollow"
            >
              粤公网安备44030002002210号
            </a>

            <div class="a">深圳天浩银雄科技有限公司</div>
            <div style="margin: 0 15px 0 10px">|</div>
            <div><a href="policy">网站条款</a></div>
            <div style="margin: 0 15px">|</div>
            <a href="privacy">隐私政策</a>
            <!-- <div class="a"><img src="public/static//images/pic.gif" alt=""></div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "pcFooter",
  props: {
    position: Number,
  },
  setup() {
    const handleTopClick = (index) => {
      sessionStorage.setItem("store", index);
    };
    const toPath = () => {
      window.open('https://ms-paige.com/','_blank')
    };
    const handleEnter = (index) => {
      if (index == 1) {
        const box = document.querySelector(".weixin");
        box.style.opacity = 1;
        box.style.height = "auto";
      }
      if (index == 2) {
        const box = document.querySelector(".weibo");
        box.style.opacity = 1;
        box.style.height = "auto";
      }
      if (index == 3) {
        const box = document.querySelector(".gw");
        const aaa = document.querySelector(".aaa");
        aaa.style.color = '#fed8be'
        box.style.opacity = 1;
        box.style.height = "auto";
      }
    };
    const handleLeave = (index) => {
      if (index == 1) {
        const box = document.querySelector(".weixin");
        box.style.opacity = 0;
        box.style.height = "0";
      }
      if (index == 2) {
        const box = document.querySelector(".weibo");
        box.style.opacity = 0;
        box.style.height = "0";
      }

      if (index == 3) {
        const box = document.querySelector(".gw");
        box.style.opacity = 0;
        box.style.height = "0";
        const aaa = document.querySelector(".aaa");
        aaa.style.color = '#fff'
      }
    };

    const downloadFile = () => {
      var a = document.createElement("a");
      a.href = "/static/zbwj.doc";
      a.download = "招标文件.doc";
      a.style.display = "none"; 
      document.body.appendChild(a);
      a.click();   
      a.remove();     
    };

    const downloadExcel = () => {
      var a = document.createElement("a");
      a.href = "/static/zbbg.docx";
      a.download = "招标表格.docx";
      a.style.display = "none"; 
      document.body.appendChild(a);
      a.click();   
      a.remove();     
    };

    return {
      handleEnter,
      handleLeave,
      handleTopClick,
      toPath,
      downloadFile,
      downloadExcel
    };
  },
};
</script>
<style scoped lang="scss">
.footer {
  position: fixed;
  padding: 89px 240px 36px 240px;
  width: 100%;
  left: 0;
  bottom: 0;
  z-index: 1;
  background: #000;
  display: flex;
  flex-direction: column;
}

.pos {
  position: absolute;
}

.footer .logo {
  display: block;
  width: 266px;
}

.footer .logo img {
  display: block;
  width: 100%;
}

.footer .ftop {
  margin-bottom: 40px;
  display: flex;
  color: rgba(255, 255, 255, 0.7);
  justify-content: space-between;
  border-bottom: 1px solid #444444;
}

.footer .ftop .flt {
  display: flex;
  flex-direction: column;
}

.footer .ftop .flt .ftt {
  margin-bottom: 10px;
  color: #fff;
}

.footer .fttimg {
  width: 84px;
  height: 84px;
}

.footer .ftop .a {
  display: flex;
  margin: 15px 0 10px 0;
  flex-wrap: wrap;
  width: 200px;
  align-items: center;
  position: relative;
}

.footer .ftop .a a {
  display: block;
  position: relative;
  margin-right: 20px;

  &:last-child {
    width: 18px;
    padding-bottom: 24px;
  }
}

.subTitle {
  font-size: 11px;
  color: rgba(255, 255, 255, 0.4) !important;
}

.footer .ftop .a a img {
  height: 18px;
  object-fit: contain;
  width:27px;
}

.footer .ftop .flt .dh {
  color: #fff;
  letter-spacing: 2px;
}

.footer .ftop .frt {
  width: 100%;
  padding-bottom: 40px;
}

.footer .ftop .frt .dl1 {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
}

.footer .ftop .frt .dl1 div a {
  display: block;
  margin-bottom: 25px;
  color: #fff;
}

.footer .ftop .frt .dl1 div a:nth-child(1) {
  font-size: 14px;
}

.footer .ftop .frt .dl1 div a:last-child {
  margin-bottom: 0;
}

.footer .ftop .frt .dl1 div a span {
  display: inline-block;
  position: relative;
  color: rgba(255, 255, 255, 0.7);
}

.footer .ftop .frt .dl1 div a span s {
  position: absolute;
  left: 0;
  bottom: -4px;
  z-index: 1;
  width: 0%;
  background: #c4946c;
  height: 1px;
}

.footer .ftop .frt .dl1 div a:hover span {
  color: #c4946c !important;
}

.footer .ftop .frt .dl1 div a:hover span s {
  width: 100%;
}

.footer .fbtm {
  display: flex;
  justify-content: space-between;
  color: #7f7f7f;
  padding-top: 25px;
}

.footer .fbtm * {
  color: #7f7f7f;
}

.footer .fbtm .flt {
  display: flex;
  align-items: center;
  align-content: center;
}

.footer .fbtm .flt .a {
  display: block;
  margin-right: 10px;
}

.footer .fbtm a:hover {
  color: #c4946c;
}

.weixin {
  position: absolute;
  top: 25px;
  left: -30px;
  object-fit: contain;
  width: 86px;
  z-index: 3;
  opacity: 0;
  height: 0;
}

.weibo {
  position: absolute;
  top: 25px;
  left: 10px;
  object-fit: contain;
  width: 86px;
  height: 0;
  z-index: 3;
  opacity: 0;
}

// .gws{
//   position: relative;
//   width: 100px;
// }
.gw {
  position: absolute;
  top: 25px;
  left: -15px;
  object-fit: contain;
  width: 86px;
  height: 0;
  z-index: 3;
  opacity: 0;
  cursor: pointer;
}
</style>