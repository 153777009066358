<template>
  <div class="home">
    <!-- <video src="../assets/img/1.mp4" id="vid1" loop autoplay muted x5-playsinline playsinline webkit-playsinline></video> -->
    <div class="i1 ce">
      <dl>
        <dd class="in_dd3" v-if="resData.length">
          <video
            :src="resData[0].productVideoUrl"
            ref="vid1"
            loop
            autoplay
            :muted="isAudio"
            x5-playsinline
            playsinline
            webkit-playsinline
          ></video>
          <div class="mc"></div>
          <div class="txt" style="opacity: 1">
            <div class="title f_24">
              <img src="../assets/img/icon-t.png" alt="" />
              {{ resData[0].productName }}
            </div>
            <div class="tt f_48">{{ resData[0].title }}</div>
            <div class="a alltime">
              <a class="more pointer" href="/introduce">
                <s></s>
                <span>了解更多</span>
              </a>
              <a class="more pointer" href="/buy">
                <s></s>
                <span>即刻选购</span>
              </a>
            </div>
          </div>
          <div class="controls">
            <div class="img">
              <img
                src="../assets/img/mute.png"
                alt=""
                v-if="!isAudio"
                class="pointer"
                @click="isAudio = !isAudio"
              />
              <img
                src="../assets/img/unmute.png"
                alt=""
                v-if="isAudio"
                class="pointer"
                @click="isAudio = !isAudio"
              />
            </div>
            <img
              src="../assets/img/play.png"
              alt=""
              v-if="!isplay"
              class="pointer"
              @click="toControls(1)"
            />
            <img
              src="../assets/img/pause.png"
              alt=""
              v-if="isplay"
              class="pointer"
              @click="toControls(2)"
            />
          </div>
        </dd>
        <dd class="in_dd2" v-if="resData.length">
          <video
            :src="resData[1].productVideoUrl"
            ref="vid2"
            loop
            autoplay
            :muted="isAudio2"
            x5-playsinline=""
            playsinline=""
            webkit-playsinline=""
          ></video>
          <div class="mc"></div>
          <div class="txt" style="opacity: 1">
            <!-- <div class="title f_24">
              <img src="../assets/img/icon-t.png" alt="" />
              {{ resData[1].productName }}
            </div> -->
            <div class="tt f_48">{{ resData[1].title }}</div>
            <div class="a alltime">
              <a class="more pointer" href="/introduce">
                <s></s>
                <span>了解更多</span>
              </a>
              <a class="more pointer" href="/buy">
                <s></s>
                <span>即刻选购</span>
              </a>
            </div>
          </div>
          <div class="controls">
            <div class="img">
              <img
                src="../assets/img/mute.png"
                alt=""
                v-if="!isAudio2"
                class="pointer"
                @click="isAudio2 = !isAudio2"
              />
              <img
                src="../assets/img/unmute.png"
                alt=""
                v-if="isAudio2"
                class="pointer"
                @click="isAudio2 = !isAudio2"
              />
            </div>
            <img
              src="../assets/img/play.png"
              alt=""
              v-if="!isplay2"
              class="pointer"
              @click="toControls(3)"
            />
            <img
              src="../assets/img/pause.png"
              alt=""
              v-if="isplay2"
              class="pointer"
              @click="toControls(4)"
            />
          </div>
        </dd>
        <!-- 轮播列表 -->
        <dd class="in_dd_swiper">
          <swiper
            v-if="carouselList.length"
            :grabCursor="true"
            :loop="true"
            :autoplay="{
              delay: 3000,
              disableOnInteraction: false,
              pauseOnMouseEnter: true,
            }"
            pagination
            :modules="modules"
            class="mySwiper"
          >
            <swiper-slide v-for="(item, index) in carouselList" :key="index">
              <div class="ss">
                <!-- <div class="mcn"></div> -->
                <div class="s1">
                  <img :src="item.pictureUrl" alt="" />
                </div>
                <div class="a alltime">
                  <div class="subTitle f_24">{{ item.subTitle }}</div>
                  <div class="tet f_48">{{ item.title }}</div>
                  <div class="more pointer" @click="handleCaClick(index)">
                    <s></s>
                    <span>了解更多</span>
                  </div>
                </div>
              </div>
            </swiper-slide>
          </swiper>
        </dd>
        <!-- 左右方形视频 -->
        <dd class="in_dd1">
          <!-- 改这里 -->
          <!-- <div class="left">
            <img src="../assets/img/left-img.png" alt="" />
            <div class="mct alltime3">
              <img src="../assets/img/time-b.png" alt="" />
              <div class="yry">玉如意 美容仪</div>
            </div>
          </div>
          <div class="right">
            <img src="../assets/img/right-img.png" alt="" />
            <div class="mct alltime3">
              <img src="../assets/img/24k.png" alt="" />
              <div class="yry">金箔水润凝胶</div>
            </div>
          </div> -->
          <div class="left" v-for="(item, index) in slist" :key="index">
            <video
              ref="vidTwo"
              loop
              autoplay
              muted
              x5-playsinline
              playsinline
              webkit-playsinline
              :src="item.productVideoUrl"
            ></video>
            <div class="mct alltime3">
              <div class="subTitle ">{{item.subTitle}}</div>
              <div class="yry">{{item.title}}</div>
            </div>
          </div>
        </dd>
        <!-- 前沿资讯 -->
        <dd class="in_dd_news wow fadeInUp" data-wow-delay="200ms">
          <div class="left pointer" @click="leftAc">
            <img class="imgg" src="../assets/img/left-arrr.png" alt="" />
          </div>
          <div class="right pointer" @click="rightAc">
            <img class="imgg" src="../assets/img/right-arr.png" alt="" />
          </div>
          <div class="title" style="color:#fff">前沿资讯</div>
          <swiper
            v-if="list.length"
            :slidesPerView="2.5"
            :centeredSlides="true"
            :spaceBetween="23"
            :grabCursor="true"
            :loop="true"
            :freeMode="true"
            :autoplay="{
              delay: 2500,
              disableOnInteraction: false,
              pauseOnMouseEnter: true,
            }"
            :modules="modules"
            class="mySwiper1"
          >
            <!-- :modules="modules" -->
            <swiper-slide v-for="(item, index) in list" :key="index">
              <div class="ss">
                <div class="mcn"></div>
                <div class="s1">
                  <img :src="item.pictureUrl" alt="" />
                </div>
                <div class="a alltime">
                  <div class="tet">{{ item.title }}</div>
                  <div class="more pointer" @click="handleClick(index)">
                    <s></s>
                    <span>探索更多</span>
                  </div>
                </div>
              </div>
            </swiper-slide>
          </swiper>
        </dd>
        <dd class="in_dd_service">
          <pc-service class="pcService"></pc-service>
        </dd>
      </dl>
    </div>
  </div>
</template>

<script>
// import pcFooter from "@/components/footer.vue";
import pcService from "@/components/service.vue";
import { Swiper, SwiperSlide } from "swiper/vue";
import { Autoplay, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import { reactive, toRefs, onMounted, ref } from "vue";
import { WOW } from "wowjs";
import { useRouter } from "vue-router"; //路由
import {
  homePageVideos,
  homePageInformation,
  smallVideos,
  carouselPicture,
} from "../api";
export default {
  name: "HomeView",

  components: {
    // pcFooter,
    Swiper,
    SwiperSlide,
    pcService,
  },
  setup() {
    const router = useRouter(); // 路由
    const data = reactive({
      list: [],
      isplay: false,
      isAudio: true,
      isplay2: false,
      isAudio2: true,
      resData: [],
      slist: [],
      carouselList: [],
    });

    const handleCaClick = (index) => {
      router.push({
        path: data.carouselList[index].jumpUrl,
      });
    };
    const handleClick = (index) => {
      const swiper = document.querySelector(".mySwiper1").swiper;
      console.log(swiper.realIndex);
      router.push({
        path: "/news",
        query: {
          type: 1,
          index: index,
        },
      });
    };
    const getData = () => {
      homePageVideos().then((res) => {
        data.resData = res.data;
        console.log(data.resData);
      });
      smallVideos().then((res) => {
        data.slist = res.data;
      });
      carouselPicture().then((res) => {
        data.carouselList = res.data;
      });
      homePageInformation().then((res) => {
        data.list = res.data;
      });
    };
    const leftAc = () => {
      const swiper = document.querySelector(".mySwiper1").swiper;
      // 修改
      swiper.slidePrev();
    };
    const rightAc = () => {
      const swiper = document.querySelector(".mySwiper1").swiper;
      // 修改
      swiper.slideNext();
    };
    const vid1 = ref();
    const vid2 = ref();
    const toControls = (index) => {
      if (index == 1) {
        data.isplay = true;
        vid1.value.pause();
      }
      if (index == 2) {
        data.isplay = false;
        vid1.value.play();
      }
      if (index == 3) {
        data.isplay2 = true;
        vid2.value.pause();
      }
      if (index == 4) {
        data.isplay2 = false;
        vid2.value.play();
      }
    };
    onMounted(async () => {
      new WOW({
        boxClass: "wow", // 需要执行动画的元素的 class
        animateClass: "animated", //animation.css 动画的 class
        offset: 100, // 距离可视区域多少开始执行动画
        mobile: false, // 是否在移动设备上执行动画
        live: true, // 异步加载的内容是否有效
        callback: function (box) {
          console.log(box);
          box.classList.add("wow1");
        },
      }).init();

      getData();
    });
    return {
      ...toRefs(data),
      getData,
      leftAc,
      rightAc,
      toControls,
      vid1,
      vid2,
      handleClick,
      handleCaClick,
      modules: [Autoplay, Pagination],
    };
  },
};
</script>

<style lang="scss" >
.home {
  width: 100%;
  position: relative;
  padding-bottom: 500px;
  background: #000;
}

.ce {
  position: relative;
  z-index: 3;
  background: #000;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.i1 dl dd {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.i1 dl .in_dd1 {
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: 16px;
  justify-content: space-between;
}

.i1 dl .in_dd1 .left {
  position: relative;
  width: calc(50% - 11px);
}

.i1 dl .in_dd1 .left img {
  width: 100%;
}

.i1 dl .in_dd1 .right {
  position: relative;
  width: calc(50% - 11px);
}

.i1 dl .in_dd1 .right img {
  width: 100%;
}

.i1 dl .in_dd1 .yry {
  font-size: 28px;
  color: #000;
}

.i1 dl .in_dd1 .subTitle {
  color: #000;
  font-size: 32px;
  margin-bottom: 10px;
  font-family: "Didot-Regular";
}

.i1 dl dd video {
  display: block;
  width: 100%;
  pointer-events: none;
}

.i1 dl dd .mc {
  position: absolute;
  width: 100%;
  height: 28.13731vw;
  bottom: 0;
  left: 0;
  z-index: 2;
  background-image: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.9) 0,
    rgba(0, 0, 0, 0.000000001)
  );
  pointer-events: none;
}

.i1 dl .in_dd1 .left .mct,
.i1 dl .in_dd1 .right .mct {
  position: absolute;
  width: 100%;
  padding: 20px;
  // height: 171px;÷
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  bottom: 0;
  left: 0;
  background-image: url("../assets/img/bg.png");
  pointer-events: none;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  opacity: 0;
}

.i1 dl .in_dd1 .left .mct img {
  width: 206px;
  margin: 27px 0;
}

.i1 dl .in_dd1 .right .mct img {
  width: 346px;
  margin: 27px 0;
}

.controls {
  position: absolute;
  right: 4%;
  z-index: 3;
  bottom: 7%;
  display: flex;
  flex-direction: row;
}

.controls {
  img {
    width: 44px;
    object-fit: contain;
  }

  .img {
    width: 44px;
    display: flex;
    justify-content: center;
    margin-right: 10px;

    img {
      width: 18px;
      object-fit: contain;
    }
  }
}

.i1 dl dd .txt {
  position: absolute;
  width: 100%;
  left: 0;
  z-index: 3;
  bottom: 7%;
  color: #fff;
  text-align: center;
}

.i1 dl dd .txt .en {
  font-family: Arial;
  letter-spacing: 1.3px;
  font-weight: 750;
}

.i1 dl dd .txt .tt {
  margin: 13px 0 35px;
  letter-spacing: 1.2px;
  font-family: "HarmonyOS_Sans_SC_Light";
}

.i1 dl dd .txt .title {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Fontquan-XinYiJiXiangSong-Regular";
}

.i1 dl dd .txt .title img {
  width: 110px;
  margin-right: 10px;
}

.i1 dl dd .txt .a {
  display: flex;
  justify-content: center;
}

.i1 dl dd .txt .a .more {
  display: block;
  width: 164px;
  height: 47px;
  line-height: 47px;
  background-image: url("../assets/img/btn-more.png");
  background-position: top left;
  background-size: 100%;
  text-align: center;
  position: relative;
  overflow: hidden;
  z-index: 3;
  margin: 0 15px;
  font-size: 16px;
}

.i1 dl dd .txt .a .more span {
  position: relative;
  z-index: 3;
  color: #ffecc5;
  letter-spacing: 2px;
}

.i1 dl .in_dd3 .txt .a .more s {
  display: block;
  width: 0%;
  height: 100%;
  bottom: 0;
  left: 0;
  z-index: 1;
  background: url("../assets/img/btn-more-g.jpg") center no-repeat;
  position: absolute;
}

.i1 dl .in_dd2 .txt .a .more s {
  display: block;
  width: 0;
  height: 100%;
  bottom: 0;
  left: 0;
  z-index: 1;
  background: url("../assets/img/btn-more-g.jpg") center no-repeat;
  position: absolute;
}

.i1 dl .in_dd2 .txt .a .more:hover s {
  width: 100%;
}

.i1 dl .in_dd3 .txt .a .more:hover s {
  width: 100%;
}

.i1 dl dd .txt .a .more:hover span {
  color: #fff;
}

.i1 dl .in_dd1 img:hover .txt {
  opacity: 1;
}

.i1 dl .in_dd1 .left:hover .mct,
.i1 dl .in_dd1 .right:hover .mct {
  opacity: 1;
}

.i1 dl .in_dd_news {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}

.in_dd_news .left {
  position: absolute;
  top: 59%;
  left: 31%;
  z-index: 8;
  color: #fff;
}

.imgg {
  width: 30px;
}

.i1 dl .in_dd_news .right {
  position: absolute;
  top: 59%;
  right: 31%;
  z-index: 8;
  color: #fff;
}

.i1 dl .in_dd_news .title {
  font-size: 46px;
  font-family: "HarmonyOS_Sans_SC";
  color: #000000;
  margin: 44px 0;
}

.i1 dl .in_dd_news .swiper {
  width: 100%;
  /* height: 581px; */
}

.i1 dl .in_dd_news .swiper-slide {
  width: 100%;
  text-align: center;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.i1 dl .in_dd_news .swiper-slide .ss {
  width: 100%;
}

.i1 dl .in_dd_news .swiper-slide .ss .s1 img {
  display: block;
  width: 100%;
  object-fit: cover;
}

.i1 dl .in_dd_news .swiper-slide .ss .a {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  left: 0;
  bottom: 40px;
}

.i1 dl .in_dd_news .swiper-slide .ss .mcn {
  position: absolute;
  width: 100%;
  height: 100%;
  bottom: 0;
  left: 0;
  background-image: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.9) 0,
    rgba(0, 0, 0, 0.000000001)
  );
  pointer-events: none;
}

.i1 dl .in_dd_news .swiper-slide .ss .a .tet {
  font-size: 31px;
  color: #fff;
  margin-bottom: 20px;
  font-family: "HarmonyOS_Sans_SC_Light";
}

.i1 dl .in_dd_news .swiper-slide .ss .a .more {
  display: block;
  width: 164px;
  height: 47px;
  line-height: 47px;
  background-image: url("../assets/img/btn-more.png");
  background-position: top left;
  background-size: 100%;
  text-align: center;
  position: relative;
  overflow: hidden;
  z-index: 3;
  margin: 0 15px;
  font-size: 16px;
}

.i1 dl .in_dd_news .swiper-slide .ss .a .more span {
  position: relative;
  z-index: 3;
  color: #ffecc5;
  letter-spacing: 2px;
}

.i1 dl .in_dd_news .swiper-slide .a .more s {
  display: block;
  width: 0%;
  height: 100%;
  bottom: 0;
  left: 0;
  z-index: 1;
  background: url("../assets/img/btn-more-g.jpg") center no-repeat;
  position: absolute;
}

.i1 dl .in_dd_news .swiper-slide .a .more:hover s {
  width: 100%;
}

.i1 dl .in_dd_news .swiper-slide .a .more:hover span {
  color: #fff;
}

.in_dd_service {
  width: 100%;
  margin-top: 40px;
}

.pcservice {
  width: 80% !important;
}

.in_dd_swiper .swiper {
  width: 100%;
  /* height: 581px; */
}

.in_dd_swiper .swiper-slide {
  width: 100%;
  text-align: center;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.in_dd_swiper .swiper-slide .ss {
  width: 100%;
}

.in_dd_swiper .swiper-slide .ss .s1 img {
  display: block;
  width: 100%;
  object-fit: cover;
}

.in_dd_swiper .swiper-slide .ss .a {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  left: 0;
  bottom: 60px;
}

.in_dd_swiper .swiper-slide .ss .mcn {
  position: absolute;
  width: 100%;
  height: 100%;
  bottom: 0;
  left: 0;
  background-image: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.9) 0,
    rgba(0, 0, 0, 0.000000001)
  );
  pointer-events: none;
}

.in_dd_swiper .swiper-slide .ss .a .tet {
  // font-size: 31px;÷
  color: #fff;
  margin-bottom: 20px;
  font-family: "HarmonyOS_Sans_SC_Light";
}

.in_dd_swiper .swiper-slide .ss .a .subTitle {
  // font-size: 22px;
  color: #fff;
  margin-bottom: 20px;
  font-family: "HarmonyOS_Sans_SC_Light";
}

.in_dd_swiper .swiper-slide .ss .a .more {
  display: block;
  width: 164px;
  height: 47px;
  line-height: 47px;
  background-image: url("../assets/img/btn-more.png");
  background-position: top left;
  background-size: 100%;
  text-align: center;
  position: relative;
  overflow: hidden;
  z-index: 3;
  margin: 0 15px;
  font-size: 16px;
}

.in_dd_swiper .swiper-slide .ss .a .more span {
  position: relative;
  z-index: 3;
  color: #ffecc5;
  letter-spacing: 2px;
}

.in_dd_swiper .swiper-slide .a .more s {
  display: block;
  width: 0%;
  height: 100%;
  bottom: 0;
  left: 0;
  z-index: 1;
  background: url("../assets/img/btn-more-g.jpg") center no-repeat;
  position: absolute;
}

.in_dd_swiper .swiper-slide .a .more:hover s {
  width: 100%;
}

.in_dd_swiper .swiper-slide .a .more:hover span {
  color: #fff;
}

.swiper-pagination-bullet-active {
  background-color: #fff;
}

.swiper-pagination-bullet {
  background-color: lightgrey;
}

.swiper-pagination {
  margin-bottom: 15px;
}
</style>
