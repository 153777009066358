<template>
  <pc-header :isBg="isBg" :isblackBg="isblackBg"></pc-header>
  <div
    class="toTop pointer alltime3"
    @click="back"
    ref="totop"
    style="display: none"
  >
    <img src="./assets/img/top2.jpg" alt="" />
    <img src="./assets/img/top.jpg" alt="" />
  </div>
  <router-view />
  <pc-footer></pc-footer>
</template>

<script>
import { defineComponent, onMounted, reactive, toRefs, ref, watch } from "vue";
import pcHeader from "@/components/header.vue";
import pcFooter from "@/components/footer.vue";
// import { useStore } from 'vuex'
import { useRoute } from "vue-router"; //路由
export default defineComponent({
  components: {
    pcHeader,
    pcFooter,
  },
  setup() {
    const totop = ref();
    const route = useRoute(); // 路由
    const data = reactive({
      oldScrollTop: 0,
      show: true,
      isBg: false,
      isblackBg:true
    });
    const back = () => {
      window.scrollTo({
        // top: document.documentElement.offsetHeight, //回到底部
        top: 0, //回到顶部
        left: 0,
        behavior: "smooth", //smooth 平滑；auto:瞬间
      });
    };

    const handleScroll = () => {
      if (route.path == "/shopMap" ) {
          return
        } 
      // 滚动条距文档顶部的距离
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      // 滚动条滚动的距离
      let scrollStep = scrollTop - data.oldScrollTop;
      // console.log("header 滚动距离 ", scrollTop);
      // 更新——滚动前，滚动条距文档顶部的距离
      data.oldScrollTop = scrollTop;

      //变量windowHeight是可视区的高度
      let windowHeight =
        document.documentElement.clientHeight || document.body.clientHeight;
      //变量scrollHeight是滚动条的总高度
      let scrollHeight =
        document.documentElement.scrollHeight || document.body.scrollHeight;

      //滚动条到底部的条件
      if (scrollTop + windowHeight == scrollHeight) {
        //你想做的事情
        // console.log("header  你已经到底部了");
        data.isBg = false;
      }
      if (scrollTop + windowHeight > scrollHeight - 100) {
        data.show = false;
      } else {
        data.show = true;
      }

      if (scrollStep < 0) {
        // console.log("header 滚动条向上滚动了！");
        data.show = true;
        data.isBg = false;
        data.isblackBg = false;
      } else {
        // console.log("header  滚动条向下滚动了！");
        if (scrollTop > 100) {
          data.show = false;
          data.isBg = true;
          data.isblackBg = false;
        }
      }
      // 判断是否到了最顶部
      if (scrollTop <= 120) {
        // console.log("header 到了最顶部")
        data.isblackBg = true;
      }

      // 获取浏览器卷去的高度
      if (location.pathname == "/about" || location.pathname == "/paigeNews") {
        let high =
          document.documentElement.scrollTop || document.body.scrollTop; //兼容各浏览器
        if (high >= 500) {
          totop.value.style.display = "block";
        } else {
          totop.value.style.display = "none";
        }
      } else {
        totop.value.style.display = "none";
      }
    };
    watch(
      () => route.path,
      (newRoute) => {
        console.log(newRoute);
        if (newRoute != "/about" || newRoute != "/paigeNews") {
          totop.value.style.display = "none";
        } 
      }
    );

    onMounted(async () => {
      window.addEventListener("scroll", handleScroll);
    });
    return {
      ...toRefs(data),
      handleScroll,
      back,
      totop,
    };
  },
});
</script>
<style lang="scss">
@import url("../src/assets/common.scss");

#app {
  width: 100vw;
  height: 100vh;
  background: #000;
}

.toTop {
  position: fixed;
  right: 20px;
  bottom: 100px;
  z-index: 99;
  img {
    width: 70px;

    &:last-child {
      opacity: 0;
      right: 0;
      bottom: 0;
      position: absolute;
    }
  }
}

.toTop:hover img {
  &:first-child {
    opacity: 0;
  }

  &:last-child {
    opacity: 1;
  }
}

</style>
