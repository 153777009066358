import { createStore } from 'vuex'

export default createStore({
  state: {
    index: 0
  },
  getters: {
  },
  mutations: {
    setState(state, index) {
      state.index = index;
    },
  },
  actions: {
  },
  modules: {
  }
})
